import React from 'react';

import {
  Text,
  Container,
  Heading,
  Flex,
  Image,
  Stack,
} from '@chakra-ui/react';

import workspace from '../Workspace.svg';
import invite from '../Invite.svg';
import share from '../Share.svg';
import swirl from '../Swirl.png';

const Item = ({ title, desc, icon }) => {
  return (
    <Flex flexShrink={0} justifyContent="space-between" flexDirection="column" h={{base: "264px", lg: "400px"}} w={{base: "100%", lg: "345px"}} bg="linear-gradient(11.68deg, #4800BA -3.49%, #6811BE 33.16%, #AD04F8 69.81%, #FE60A4 107.57%)" borderRadius="40px" p="40px">
      <Image src={icon} w="40px" />
      <Flex flexDirection="column" height={{base: "auto", lg: "136px"}}>
        <Heading as="h1" fontSize={{base: "18px", lg: "20px"}} lineHeight="24px">{title}</Heading>
        <Text fontSize={{base: "14px", lg: "16px"}} lineHeight={{base: "21px", lg: "24px"}} mt={{base: "8px", lg: "16px"}} color="rgba(255, 255, 255, 0.5)">{desc}</Text>
      </Flex>
    </Flex>
  )
}

const Collaborate = () => {
  return (
    <Flex flexDirection="column" alignItems="center" bg="dark" bgImage={swirl} bgPosition="top center" bgRepeat="no-repeat" color="white" py={{base: "40px", lg: "128px"}} marginTop={{base: "40px", lg: "128px"}}>
      <Container maxW="container.xl">
        <Flex flexDirection="column" w={{base: "327px", lg: "954px"}} textAlign="center" marginX="auto" marginBottom={{base: "40px", lg: "64px"}}>
          <Heading fontSize={{base: "40px", lg: "48px"}} lineHeight={{base: "48px", lg: "56px"}} fontWeight="800">Cloud-native collaborations to supercharge teamwork</Heading>
          <Text fontSize={{base:"16px", lg: "20px"}} lineHeight={{base: "24px", lg: "32px"}} color="rgba(255, 255, 255, 0.5)" marginTop={{base: "16px", lg: "24px"}}>Sharing is caring, the power of a successful digital signage implementation.</Text>
        </Flex>
        <Stack direction={{base: "column", lg: "row"}} spacing={{base: "16px", lg: "32px" }} justifyContent="center">
          <Item title="Invite your team" desc="Collaborate with team members in a workspace, whether these are your colleagues or external stakeholders." icon={invite} />
          <Item title="Share content" desc="Share your content between workspaces. Create efficiency between other locations, branches or organizations." icon={share} />
          <Item title="Workspaces" desc="Bundle your content and workflow in separate workspaces. Make logical groups like divisions or locations." icon={workspace} />
        </Stack>
      </Container>
    </Flex>
  )
}

export default Collaborate