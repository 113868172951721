import {
  Box,
  // Text,
  Button,
  Container,
  Heading,
  Flex,
  Image,
  Link,
  Stack,
} from '@chakra-ui/react';

import logo from './Lumicast_Logo.svg';
import footer from './Footer.png';

const Footer = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" w="100%" h={{ base: 'auto', lg: "764px" }} bgImage={footer} bgPosition="center" bgRepeat="no-repeat" overflow="hidden" pt={{ base: "85px", lg: "128px" }} borderTopLeftRadius="100px" borderTopRightRadius="100px">
      <Container maxW="container.xl">
        <Heading as="h1" fontSize={{ base: "56px", lg: "120px" }} lineHeight={{ base: "56px", lg: "120px" }} fontWeight="800" color="white" textAlign="center" maxWidth="954px" mx="auto">Ready to get started?</Heading>
        <Flex justifyContent="center" mt={{ base: "40px", lg: "48px" }}>
          <Button borderRadius="100px" size="xl" mr="3" bg="pink" color="white" href="https://app.lumicast.com" target="_blank" as="a">Login</Button>
          <Button borderRadius="100px" size="xl" color="white" bg="rgba(255, 255, 255, 0.2);" href="https://webjvxkckle.typeform.com/to/HEejrRRs" as="a" target="_blank">Request Demo</Button>
        </Flex>
        {/* <Text fontSize="16px" color="white" marginTop="32px" textAlign="center">14-day free trial. Verification required.</Text> */}
      </Container>

      <Box borderTop="1px solid rgba(255, 255, 255, 0.25)" mt={{ base: "48px", lg: 0 }}>
        <Container maxW="container.xl">
          <Flex alignItems="center" justifyContent="space-between" h={{ base: 'auto', lg: "110px" }} flexDirection={{ base: "column", lg: "row" }}>
            <Image src={logo} width={{ base: "180px", lg: "252px" }} mt={{ base: "48px", lg: 0 }} />
            <Stack direction={{ base: "column", lg: "row" }} my={{ base: "40px", lg: 0 }} spacing={{ base: "32px", lg: "48px" }} justifyContent="center" textAlign={{ base: 'center', lg: 'left' }}>
              <Link href="https://status.lumicast.com" isExternal color="white" fontSize="16px" lineHeight="24px">Status</Link>
              <Link href="NLdigital_Terms_EN.pdf" isExternal color="white" fontSize="16px" lineHeight="24px">Terms & Conditions</Link>
            </Stack>
          </Flex>
        </Container>
      </Box>

    </Flex>
  )
}

export default Footer;