
import {
  Box,
  Text,
  // Button,
  Container,
  Heading,
  Flex,
  Image,
  Grid,
} from '@chakra-ui/react';


import draganddrop from '../icons/drag-and-drop.svg';
import templates from '../icons/templates.svg';
import apps from '../icons/apps.svg';
import schedule from '../icons/schedule.svg';
import campaign from '../icons/campaign.svg';
import emergency from '../icons/emergency.svg';
import database from '../icons/database.svg';
import tagging from '../icons/tagging.svg';
import offline from '../icons/offline.svg';
import proofofplay from '../icons/proofofplay.svg';
import hybrid from '../icons/hybrid.svg';
import deployment from '../icons/deployment.svg';

const groups = [
  {
    name: 'create',
    title: 'Harvest & Create',
    desc: 'Use existing content from your social media apps or start creating new content from scratch.',
    features: [
      { title: "Drag and drop", desc: "Easy to use content editor that empowers your creativity.", icon: draganddrop },
      { title: "Templates", desc: "Create messages with a WYSIWYG editor by using your predefined templates.", icon: templates },
      { title: "Apps", desc: "Integrate with your social media apps and news, weather, public transportation, or other content provider.", icon: apps },

    ]
  },
  {
    name: 'schedule',
    title: 'Schedule & Publish',
    desc: "When your content is ready, push it to your screens. Use schedules, tags event and data triggers to target your audience.",
    features: [
      { title: "Advanced scheduling", desc: "Schedule your content based on the time of the day, week, month, year or create repetitive schedules.", icon: schedule },
      { title: "Campaigns", desc: "Define campaigns with scheduling or tagging containing assets for multiple resolutions for advertising.", icon: campaign },
      { title: "Programmatic content", desc: "Implement real-time datasets that enable you to push content to screens based on certain conditions.", icon: database },
      { title: "Emergency messaging", desc: "Instantly overrule content that is currently playing on your screens with a special campaign.", icon: emergency },
      { title: "Grouping & Tagging", desc: "Use grouping and tagging to narrow your scope and target the right audience.", icon: tagging },

    ]
  },
  {
    name: 'display',
    title: 'Display & Manage',
    desc: 'Display your content with intelligent player software that provides you with real-time insights for easy content management and reporting.',
    features: [
      { title: "Offline playback", desc: "When internet connection is lost, Lumicast will continue playing your content and will update when back online.", icon: offline },
      { title: "Proof of Play", desc: "Lumicast keeps track of content displayed on each of your screens to provide you insights with diagrams and exports.", icon: proofofplay },
      { title: "Platform and geographical independent", desc: "Lumicast is a cloud-based platform that operates on many industry standard products allowing you to work from anywhere in the world.", icon: hybrid },
      { title: "Built for small and large scale deployments", desc: "Lumicast implements concepts that work for small and large scale deployments.", icon: deployment }
    ]
  }
]

const Feature = ({title, desc, icon}) => {
  return (
    <Box>
      <Image src={icon} marginBottom={{base: "24px", lg: "30px"}} />
      <Heading as="h1" fontSize={{base: "18px", lg: "20px"}} lineHeight="24px"  marginBottom={{base: "8px", lg: "16px"}}>{title}</Heading>
      <Text fontSize="16px" lineHeight="24px" color="rgba(18, 31, 51, 0.5);">{desc}</Text>
    </Box>
  )
}

const Features = ({title, desc, features}) => {
  return (
    <Container maxW="container.xl">
      <Flex bg="#F9F9F9" p={{base: "32px", lg: "128px"}} borderRadius="40px" flexDirection={["column", "column", "column", "row"]} justifyContent="space-between" alignItems={{base: "center", lg: "normal"}} marginTop={{base: "40px", lg: "128px"}}>
        <Flex flexDirection="column" width={{base: "300px", lg: "344px"}} textAlign={{base: "center", lg: "left" }}>
          <Heading as="h1" fontSize={{base:"24px", lg: "32px"}} lineHeight={{base:"32px", lg: "40px"}} marginBottom="4">{title}</Heading>
          <Text fontSize={{base: "16px", lg: "xl"}} lineHeight={{base:"24px", lg: "32px"}} color="rgba(18, 31, 51, 0.5);">{desc}</Text>
          {/* <Button borderRadius="100px" size="xl" mr="3" bg="pink" color="white" w="148px" marginTop="8">Learn more</Button> */}
        </Flex>
        <Grid templateColumns={["repeat(1, 1fr)","repeat(1, 1fr)","repeat(1, 1fr)","repeat(2, 1fr)"]} gap={{base: "32px", lg: "64px"}} w={{base: "300px", lg: "580px"}} marginTop={{base: "32px", lg: 0}}>
          {features.map((x, i) => {
            return <Feature key={i} title={x.title} desc={x.desc} icon={x.icon}/>
          })}
        </Grid>
      </Flex>
    </Container>
  )
}

const FeatureGroup = ({name}) => {
  const group = groups.find(g => g.name === name)
  if (!group) return null
  return (
    <Features title={group.title} desc={group.desc} features={group.features}/>
  )
}

export default FeatureGroup;