import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        color: "dark",
        lineHeight: "24px"
      }
    },
  },
  colorSchemes: {
    brand: {
      bg: '#FE60A4'
    }
  },
  colors: {
    pink: '#FE60A4',
    blue: '#4800BA',
    dark: '#121F33',
  },
  fonts: {
    heading: "Gilroy",
    body: "Inter"
  },
  components: {

    Button: {
      // baseStyle: {
      //   fontSize: "md"
      // },
      sizes: {
        xl: {
          h: "56px",
          fontSize: "md",
          py: "16px",
          px: "32px",
        },
      }
    },
  },
})
export default theme