import { Global } from "@emotion/react"

import GilroyRegular from './fonts/Gilroy-Regular.woff';
import GilroyBold from './fonts/Gilroy-Bold.woff';
import GilroyBlack from './fonts/Gilroy-Black.woff';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Gilroy';
        src: local('Gilroy'), url(${GilroyRegular}) format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'Gilroy';
        src: local('Gilroy'), url(${GilroyBold}) format('woff');
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: 'Gilroy';
        src: local('Gilroy'), url(${GilroyBlack}) format('woff');
        font-weight: 800;
        font-style: normal;
      }
    `}
  />
)
export default Fonts