import {
  Text,
  Container,
  Heading,
  Flex,
  Image,
  Stack,
} from '@chakra-ui/react';

import scalability from '../Scalability.svg';
import stability from '../Stability.svg';
import security from '../Security.svg';

const Pillar = ({title, desc, image}) => {
  return (
    <Flex h={{base: "264px", lg: "400px"}} justifyContent="space-between" flexDirection="column" w={{base: "100%", lg: "345px"}} flexShrink={0} bg="white" borderRadius="40px" p="40px">
      <Image src={image} w="40px" />
      <Flex flexDirection="column" height={{base: "auto", lg: "136px"}}>
        <Heading as="h1" fontSize={{base: "18px", lg: "20px"}} lineHeight="24px">{title}</Heading>
        <Text fontSize={{base: "14px", lg: "16px"}} lineHeight={{base: "21px", lg: "24px"}} mt={{base: "8px", lg: "16px"}} color="rgba(0, 0, 0, 0.5)">{desc}</Text>
      </Flex>
    </Flex>
  )
}


const Pillars = () => {
  return (
    <Flex flexDirection="column" alignItems="center" bg="linear-gradient(100.7deg, #4800BA 7.94%, #6811BE 41.58%, #AD04F8 75.22%, #FE60A4 109.87%)" py={{base: "40px", lg: "128px"}} marginTop={{ base: "40px", lg: "128px"}}>
      <Container maxW="container.xl">
        <Flex flexDirection="column" w={{base: "327px", lg: "954px"}} textAlign="center" marginX="auto" marginBottom={{base: "40px", lg: "64px"}}>
          <Heading fontSize={{base: "40px", lg: "48px"}} lineHeight={{base: "48px", lg: "56px"}} fontWeight="800" color="white">You grow, we scale</Heading>
          <Text fontSize={{base:"16px", lg: "20px"}} lineHeight={{base: "24px", lg: "32px"}} color="white" marginTop={{base: "16px", lg: "24px"}}>Lumicast originates from the airport industry, where systems are mission critical and subject to the highest standards. Lumicast makes this technology available for everyone, and we scale our platform just as fast as you expand your business.</Text>
        </Flex>
        <Stack direction={{base: "column", lg: "row"}} spacing={{base: "16px", lg: "32px" }} justifyContent="center">
          <Pillar title="Scalability" desc="No matter how many screens, we scale with you. There is no limit." image={scalability} />
          <Pillar title="Stability" desc="A proven uptime of 99,99% guarantees the visability of your content." image={stability} />
          <Pillar title="Security" desc="Implemented with end-to-end encryption, two factor authentication and other proven security concepts." image={security} />
        </Stack>
      </Container>
    </Flex>
  )
}

export default Pillars;