import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en_GB: {
    translation: {
      generic: {
        login: "Login",
        requestDemo: "Request Demo"
      },
      hero: {
        title: "Your visual communication platform",
        subtitle: "A cloud-native digital signage platform that empowers you to create, schedule and display content."
      }
    }
  },
  nl_NL: {
    translation: {
      generic: {
        login: "Inloggen",
        requestDemo: "Demo Aanvragen"
      },
      hero: {
        title: "Jouw content in de spotlight",
        subtitle: "Een cloudgebaseerd digital signage platform waarmee u content kunt creëren, plannen en beheren."
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en_GB",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;