import React from 'react';

import {
    Text,
    Container,
    Heading,
    Flex,
    ChakraProvider,
    Link,
    UnorderedList,
    ListItem
} from '@chakra-ui/react';

import "@fontsource/inter";

import '../i18n';

import Fonts from "../Fonts"
import theme from "../theme"

function Sla() {
    return (
        <ChakraProvider theme={theme}>
            <Fonts />
            <Container maxW="container.xl">
                <Flex bg="#F9F9F9" p={{ base: "32px", lg: "128px" }} borderRadius="40px" flexDirection="column" marginY={{ base: "40px", lg: "128px" }}>
                    <Heading as="h1" fontSize={{ base: "32px", lg: "48px" }} lineHeight={{ base: "32px", lg: "40px" }}>Service Level Agreement</Heading>
                    <Text fontSize={{ base: "18px" }} lineHeight={{ base: "24px" }} color="rgba(18, 31, 51, 1);" marginTop={{ base: "24px", lg: "24px" }}>
                        <Text fontWeight={"bold"}>Effective: August 1, 2022</Text>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Lumicast provides a 99.99% Uptime commitment for customers on a paid plan. This is our Service Level Agreement. If we fail to deliver this 99.99% Uptime commitment and your organisation on Lumicast is affected, we will apply a service credit to your account for use in the future.</Text>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>To review current and historical Uptime, visit <Link href="https://status.lumicast.com" isExternal color={'blue'}>Lumicast Status</Link>.</Text>

                        <Heading as="h2" marginTop={{ base: "24px", lg: "24px" }} fontSize={{ base: "24px", lg: "32px" }}>SLA breakdown</Heading>

                        <Heading as="h3" marginTop={{ base: "24px", lg: "24px" }} fontSize={{ base: "18px", lg: "24px" }}>Downtime</Heading>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Downtime is the overall number of minutes Lumicast was unavailable during a calendar month. Lumicast calculates unavailability using server monitoring software to measure the server side error rate, ping test results, web server tests, TCP port tests, and website tests.</Text>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Downtime excludes the following:</Text>
                        <UnorderedList marginTop={{ base: "16px" }} spacing={"24px"}>
                            <ListItem>Slowness or other performance issues with individual features (search, file uploads, etc.)</ListItem>
                            <ListItem>Issues that are related to external apps or third parties</ListItem>
                            <ListItem>Any products or features identified as pilot, alpha, beta or similar</ListItem>
                            <ListItem>External network or equipment problems outside of our reasonable control</ListItem>
                            <ListItem>Scheduled Downtime for maintenance</ListItem>
                        </UnorderedList>

                        <Heading as="h3" marginTop={{ base: "24px", lg: "24px" }} fontSize={{ base: "18px", lg: "24px" }}>Uptime Commitment</Heading>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Uptime is the percentage of total possible minutes Lumicast was available during a calendar month. Our commitment is to maintain at least 99.99% Uptime:</Text>
                        <Text marginTop={{ base: "24px", lg: "24px" }} fontWeight={"bold"}>[(total minutes in calendar month - Downtime) / total minutes in calendar month] > 99.99%</Text>

                        <Heading as="h3" marginTop={{ base: "24px", lg: "24px" }} fontSize={{ base: "18px", lg: "24px" }}>Scheduled Downtime</Heading>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Sometimes we need to perform maintenance to keep Lumicast working smoothly. If scheduled downtime is necessary, we’ll give you at least 48 hours advance notice.</Text>

                        <Heading as="h3" marginTop={{ base: "24px", lg: "24px" }} fontSize={{ base: "18px", lg: "24px" }}>Service Credits</Heading>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>If we fall short of our Uptime commitment, we’ll apply a credit to each affected account equal to 10 times the amount that the workspace (or, as applicable, org) paid during the period Lumicast was down (we call these Service Credits).</Text>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Service Credits are not refunds, cannot be exchanged into a cash amount, are capped at a maximum of 30 days of paid service, require you to have paid any outstanding invoices and expire upon termination of your customer contract. Service Credits are the sole and exclusive remedy for any failure by Lumicast to meet its obligations under this SLA.</Text>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>Note, Downtime doesn’t affect everyone at the same time or in the same way. For example, some accounts may receive Service Credits during an outage in their region, while other accounts in other regions that have not been similarly affected will not.</Text>

                        <Heading as="h3" marginTop={{ base: "24px", lg: "24px" }} fontSize={{ base: "18px", lg: "24px" }}>Updates</Heading>
                        <Text marginTop={{ base: "24px", lg: "24px" }}>As our business evolves, we may change our Service Level Agreement. Customers can review the most current version of the Service Level Agreement at any time by visiting this page.</Text>
                    </Text>
                </Flex>
            </Container>
        </ChakraProvider>
    );
}

export default Sla;
