import {
  Text,
  Flex,
  Image,
  Stack,
} from '@chakra-ui/react';

import twitter from '../icons/twitter.svg';
import instagram from '../icons/instagram.svg';
import facebook from '../icons/facebook.svg';
import youtube from '../icons/youtube.svg';
import vimeo from '../icons/vimeo.svg';

const Apps = () => {
  return (
    <Flex alignItems="center" justifyContent="center" marginTop={{base:"40px", lg: "60px"}} flexDirection={{base: "column", lg: "row" }}>
      <Text fontWeight="bold" fontSize={{base: "18px", lg: "20px"}} lineHeight="24px" fontFamily="Gilroy">Integrate with the best apps</Text>
      <Stack direction="row" spacing={{base: "32px", lg: "46px"}} marginTop={{base: "16px", lg: 0}}>
        <Image src={instagram} marginLeft={{base: 0, lg: "64px"}} alt="Instagram" w={{base: "32px", lg: "48px"}}/>
        <Image src={facebook} alt="Facebook" w={{base: "32px", lg: "48px"}}/>
        <Image src={twitter} alt="Twitter" w={{base: "32px", lg: "48px"}}/>
        <Image src={youtube} alt="YouTube" w={{base: "32px", lg: "48px"}}/>
        <Image src={vimeo} alt="Vimeo" w={{base: "32px", lg: "48px"}}/>
      </Stack>
    </Flex>
  )
}

export default Apps;