
import React, { useState } from 'react';

import {
  Text,
  Button,
  Container,
  Heading,
  Flex,
  Image,
  // Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons'

import tizen from '../Tizen.png';
import webos from '../webos.png';
import raspberrypi from '../raspberrypi.png';

const HardwareItem = ({ title, desc, image }) => {
  return (
    <Flex p={{ base: "24px", lg: "128px" }} alignItems="center" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }}>
      <Flex flexDirection="column" width={{ base: "100%", lg: "334px" }} marginTop={{ base: "16px", lg: 0 }}>
        <Heading as="h1" fontSize={{ base: "24px", lg: "32px" }} lineHeight={{ base: "32px", lg: "40px" }}>{title}</Heading>
        <Text marginTop="16px" fontSize={{ base: "16px", lg: "20px" }} lineHeight={{ base: "24px", lg: "32px" }} color="rgba(18, 31, 51, 0.5)">{desc}</Text>
        {/* <Link marginTop="16px" color="#AD04F8" fontSize="16px">Samsung Tizen installation instructions</Link> */}
      </Flex>
      <Image src={image} width={{ base: "100%", lg: "600px" }} mt={{ base: "32px", lg: "0" }} />
    </Flex>
  )
}

const Hardware = () => {
  const [platform, setPlatform] = useState('tizen');

  const platformName = (platform) => {
    if (platform === 'tizen') {
      return 'Samsung Tizen'
    } else if (platform === 'webos') {
      return 'LG webOS'
    } else if (platform === 'raspberrypi') {
      return 'Raspberry Pi'
    }
  }

  return (
    <Container maxW="container.xl">
      <Flex justifyContent="center" mt={{ base: "40px", lg: "20" }}>
        <Flex width={{ base: "360px", lg: "960px" }} textAlign="center" flexDirection="column">
          <Heading fontSize={{ base: "48px", lg: "80px" }} lineHeight={{ base: "48px", lg: "88px" }} fontWeight="800">Find your perfect digital signage device</Heading>
          <Text fontSize="xl" lineHeight="32px" color="rgba(18, 31, 51, 0.5);" marginTop="24px">Up and running within minutes with ready-made software for professional digital signage displays and industry standard platforms.</Text>
        </Flex>
      </Flex>
      <Flex bg="#F9F9F9" borderRadius="40px" flexDirection="column" marginY={{ base: "24px", lg: "128px" }}>
        <Flex py={{ base: "24px", lg: "48px" }} px={{ base: "24px", lg: 0 }} justifyContent="center" borderBottom="1px solid rgba(18, 31, 51, 0.1)">
          <Flex display={{ base: 'flex', lg: 'none' }} grow="1">
            <Menu placement="bottom">
              <MenuButton as={Button} borderRadius="100px" size="xl" bg="blue" color="white" rightIcon={<ChevronDownIcon />} isFullWidth={true} textAlign="left">
                {platformName(platform)}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setPlatform('tizen')}>{platformName('tizen')}</MenuItem>
                <MenuItem onClick={() => setPlatform('webos')}>{platformName('webos')}</MenuItem>
                <MenuItem onClick={() => setPlatform('raspberrypi')}>{platformName('raspberrypi')}</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex display={{ base: 'none', lg: 'flex' }}>
            <Button borderRadius="100px" size="xl" mr="3" bg={platform === 'tizen' ? 'blue' : null} color={platform === 'tizen' && 'white'} onClick={() => setPlatform('tizen')}>Samsung Tizen</Button>
            <Button borderRadius="100px" size="xl" mr="3" bg={platform === 'webos' ? 'blue' : null} color={platform === 'webos' && 'white'} onClick={() => setPlatform('webos')}>LG webOS</Button>
            <Button borderRadius="100px" size="xl" mr="3" bg={platform === 'raspberrypi' ? 'blue' : null} color={platform === 'raspberrypi' && 'white'} onClick={() => setPlatform('raspberrypi')}>Raspberry Pi</Button>
          </Flex>
        </Flex>
        {platform === 'tizen' &&
          <HardwareItem title="Samsung Tizen" desc="Lumicast is certified for Samsung’s Smart Signage Platform (SSSP) powered by Tizen." image={tizen} />
        }
        {platform === 'webos' &&
          <HardwareItem title="LG webOS" desc="Lumicast is certified for LG webOS." image={webos} />
        }
        {platform === 'raspberrypi' &&
          <HardwareItem title="Raspberry Pi" desc="Lumicast has an optimized Raspberry Pi solution for the use in production environments." image={raspberrypi} />
        }
      </Flex>
    </Container>
  )
}

export default Hardware;