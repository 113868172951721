import React from 'react';

import {
  ChakraProvider,
  Box,
  Text,
  Heading,
} from '@chakra-ui/react';

import "@fontsource/inter";

import '../i18n';

import Fonts from "../Fonts"
import theme from "../theme"

import Hero from "./Hero";
// import Customers from "./Customers";
import Apps from "./Apps";
import Pillars from "./Pillars";
import FeatureGroup from "./FeatureGroup";
import Collaborate from "./Collaborate";
import OpenPlatform from "./OpenPlatform";
import Hardware from "./Hardware";
import Footer from "../Footer";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Hero />
      {/* <Customers/> */}
      <Box marginBottom={{ base: "0", lg: "-58px" }} marginTop={{ base: "40px", lg: "128px" }} w={{ base: "327px", lg: '586px' }} marginX="auto" textAlign="center">
        <Heading fontSize={{ base: "40px", lg: "48px" }} lineHeight={{ base: "48px", lg: "56px" }} fontWeight="800" mx="auto">Create. Schedule. Display.</Heading>
        <Text fontSize={{ base: "16px", lg: "xl" }} lineHeight={{ base: "24px", lg: "32px" }} color="rgba(18, 31, 51, 0.5);" marginTop="24px">Design and build the perfect collaborative workspace with Lumicast’s cutting-edge features.</Text>
      </Box>
      <FeatureGroup name="create" />
      <Apps />
      <Collaborate />
      <FeatureGroup name="schedule" />
      <Pillars />
      <FeatureGroup name="display" />
      <OpenPlatform />
      <Hardware />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
