import React, { Fragment } from 'react';

import {
  Box,
  Text,
  Button,
  Container,
  Heading,
  Flex,
  Image,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuOptionGroup,
  // MenuItemOption,
} from '@chakra-ui/react';

import logo from '../Lumicast_Logo.svg';
import artwork from '../Lumicast_Artwork.png';
import screen from '../Lumicast_Screen.png';
import globe from '../globe.svg';

import retail from '../retail.jpg';
import university from '../university.jpg';
import care from '../care.jpg';
import library from '../library.jpg';
import hospitality from '../hospitality.jpg';
import corporate from '../corporate.jpg';

import { useTranslation } from 'react-i18next';

import './animate.css'

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();

//   return (
//     <Menu placement="bottom">
//       <MenuButton as={Button} borderRadius="100px" size="xl" bg="transparent" colorScheme="white" mr="3">
//         <Flex>
//           {i18n.language === 'en_GB' ? 'English (UK)' : 'Nederlands'}
//           <Image src={globe} w={4} marginLeft={2}/>
//         </Flex>
//       </MenuButton>
//       <MenuList>
//         <MenuOptionGroup defaultValue={i18n.language} type="radio">
//           <MenuItemOption value="en_GB" onClick={() => i18n.changeLanguage('en_GB')}>English (UK)</MenuItemOption>
//           <MenuItemOption value="nl_NL" onClick={() => i18n.changeLanguage('nl_NL')}>Nederlands</MenuItemOption>
//         </MenuOptionGroup>
//       </MenuList>
//     </Menu>
//   )
// }

const Buttons = () => {
  const { t } = useTranslation();

  return (
    <Flex zIndex="9" marginTop={["40px","40px","40px","0"]}>
      <Button as="a" borderRadius="100px" size="xl" mr="3" bg="pink" color="white" href="https://app.lumicast.com" target="_blank">{t('generic.login')}</Button>
      <Button as="a" borderRadius="100px" size="xl" color="white" bg="#444B60" href="https://webjvxkckle.typeform.com/to/HEejrRRs" target="_blank">{t('generic.requestDemo')}</Button>
    </Flex>
  )
}


const Hero = () => {
  const { t } = useTranslation();

  return (
    <Box w="100%" h={{ base: '480px', lg: '900px' }} bgImage={artwork} bgPosition="center" bgRepeat="no-repeat" overflow="hidden">
      <Container maxW="container.xl" m="auto" position="absolute" left="0" right="0" top="40px">
        <Flex justifyContent={["center","center","center","space-between"]} alignItems="center">
          <Image src={logo} width={{ base: '180px', lg: "252px" }}/>
          <Flex flexDirection="row" display={{ base: 'none', lg: "flex" }}>
            <Buttons/>
          </Flex>
        </Flex>
      </Container>


      <Flex flexDirection="column" justifyContent="center" h={{ base: '100%', lg: "900px" }}>
        <Container maxW="container.xl" marginY="auto" display={{ base: 'none', lg: 'block' }}>
          <Flex alignItems="center" flexDir="row">
            <Box maxWidth="720px" color="white" marginRight="80px">
              <Heading as="h1" textAlign="left" fontSize="100px" lineHeight="90px" mb="40px" fontFamily="Gilroy" fontWeight="800">{t('hero.title')}</Heading>
              <Text fontSize="xl" lineHeight="32px" textAlign="left">{t('hero.subtitle')}</Text>
            </Box>
            <Flex flexDir="column" width="100%" className="slider">
              {
                [...Array(3)].map((e, i) => (
                  <Fragment>
                    <Flex bgImage={retail} bgSize="cover" rounded="80px" width="400px" height="400px" justifyContent="flex-end" flexDirection="column" marginTop="40px">
                      <Flex m="40px" alignSelf="flex-start" bg="rgba(0, 0, 0, 0.7);" color="white" rounded="10px" py="8px" px="14px">Retail</Flex>
                    </Flex>
                    <Flex alignSelf="flex-end" bgSize="cover" bgImage={university} rounded="80px" width="300px" height="300px" justifyContent="flex-end" flexDirection="column" marginTop="40px">
                      <Flex m="40px" alignSelf="flex-start" bg="rgba(0, 0, 0, 0.7);" color="white" rounded="10px" py="8px" px="14px">Education</Flex>
                    </Flex>
                    <Flex alignSelf="flex-end" bgSize="cover" bgImage={care} rounded="80px" width="400px" height="400px" justifyContent="flex-end" flexDirection="column" marginTop="40px">
                      <Flex m="40px" alignSelf="flex-start" bg="rgba(0, 0, 0, 0.7);" color="white" rounded="10px" py="8px" px="14px">Care</Flex>
                    </Flex>
                    <Flex alignSelf="flex-end" bgSize="cover" bgImage={library} rounded="80px" width="300px" height="300px" justifyContent="flex-end" flexDirection="column" marginTop="40px">
                      <Flex m="40px" alignSelf="flex-start" bg="rgba(0, 0, 0, 0.7);" color="white" rounded="10px" py="8px" px="14px">Library</Flex>
                    </Flex>
                    <Flex alignSelf="flex-end" bgSize="cover" bgImage={hospitality} rounded="80px" width="400px" height="400px" justifyContent="flex-end" flexDirection="column" marginTop="40px">
                      <Flex m="40px" alignSelf="flex-start" bg="rgba(0, 0, 0, 0.7);" color="white" rounded="10px" py="8px" px="14px">Hospitality</Flex>
                    </Flex>
                    <Flex alignSelf="flex-end" bgSize="cover" bgImage={corporate} rounded="80px" width="300px" height="300px" justifyContent="flex-end" flexDirection="column" marginTop="40px">
                      <Flex m="40px" alignSelf="flex-start" bg="rgba(0, 0, 0, 0.7);" color="white" rounded="10px" py="8px" px="14px">Corporate</Flex>
                    </Flex>
                  </Fragment>
                ))
              }
            </Flex>
          </Flex>
        </Container>
        <Container maxW="container.xl" display={{ base: 'block', lg: 'none' }} mt="100px">
          <Flex alignItems="center" flexDir="column" mb="40px">
            <Box maxWidth="350px" color="white">
              <Heading as="h1" textAlign="center" fontSize="48px" lineHeight="48px" mb="20px" fontFamily="Gilroy" fontWeight="800">{t('hero.title')}</Heading>
              <Text fontSize="16px" lineHeight="24px" textAlign="center">{t('hero.subtitle')}</Text>
            </Box>
            <Buttons/>
          </Flex>
        </Container>
      </Flex>
    </Box>
  )
}

export default Hero;