
import {
  Text,
  Container,
  Heading,
  Flex,
  Image,
  Stack,
} from '@chakra-ui/react';

import platform from '../Lumicast_Artwork_Platform.png';
import webhook from '../Webhook.svg';
import document from '../Document.svg';

const Item = ({ title, desc, icon }) => {
  return (
    <Flex flexShrink={0} justifyContent="space-between" flexDirection="column" h={{base: "264px", lg: "400px"}} w={{base: "100%", lg: "345px"}} bg="dark" borderRadius="40px" p="40px">
      <Image src={icon} h="40px" marginRight="auto" />
      <Flex flexDirection="column" height={{base: "auto", lg: "136px"}}>
        <Heading as="h1" fontSize={{base: "18px", lg: "20px"}} lineHeight="24px" color="white">{title}</Heading>
        <Text fontSize={{base: "14px", lg: "16px"}} lineHeight={{base: "21px", lg: "24px"}} mt={{base: "8px", lg: "16px"}} color="rgba(255, 255, 255, 0.5)">{desc}</Text>
      </Flex>
    </Flex>
  )
}

const OpenPlatform = () => {
  return (
    <Flex flexDirection="column" alignItems="center" bgPosition="center 160px" bgRepeat="no-repeat" bgImage={platform} py={{base: "40px", lg: "128px"}} marginTop={{ base: "40px", lg: "128px"}}>
      <Container maxW="container.xl">
        <Flex flexDirection="column" w={{base: "327px", lg: "954px"}} textAlign="center" marginX="auto" marginBottom={{base: "40px", lg: "64px"}}>
          <Heading fontSize={{base: "40px", lg: "48px"}} lineHeight={{base: "48px", lg: "56px"}} fontWeight="800">An open platform</Heading>
          <Text fontSize={{base:"16px", lg: "20px"}} lineHeight={{base: "24px", lg: "32px"}} color="rgba(18, 31, 51, 0.5)" marginTop={{base: "16px", lg: "24px"}}>Lumicast utilizes an open principle that allows digital signage to integrate either in your own apps or third-party software.</Text>
        </Flex>
        <Stack direction={{base: "column", lg: "row"}} spacing={{base: "16px", lg: "32px" }} justifyContent="center">
          <Item title="Rest API" desc="Fully documented API interfaces that allow you to connect your application to Lumicast." icon={document}/>
          <Item title="Webhooks" desc="Create webhooks that trigger at certain conditions to push content to your platform." icon={webhook}/>
        </Stack>
      </Container>
    </Flex>
  )
}

export default OpenPlatform;